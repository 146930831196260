/* Autogenerated file. Do not edit manually. */

/* tslint:disable */
/* eslint-disable */

/*
  Fuels version: 0.79.0
  Forc version: 0.49.3
  Fuel-Core version: 0.22.1
*/

import { Interface, Contract, ContractFactory } from "fuels";
import type { Provider, Account, AbstractAddress, BytesLike, DeployContractOptions, StorageSlot } from "fuels";
import type { GameScoreContractAbi, GameScoreContractAbiInterface } from "../GameScoreContractAbi";

const _abi = {
  "types": [
    {
      "typeId": 0,
      "type": "()",
      "components": [],
      "typeParameters": null
    },
    {
      "typeId": 1,
      "type": "b256",
      "components": null,
      "typeParameters": null
    },
    {
      "typeId": 2,
      "type": "bool",
      "components": null,
      "typeParameters": null
    },
    {
      "typeId": 3,
      "type": "enum GetError",
      "components": [
        {
          "name": "UsernameDoesNotExists",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "IdIsOverMax",
          "type": 0,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 4,
      "type": "enum Option",
      "components": [
        {
          "name": "None",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "Some",
          "type": 6,
          "typeArguments": null
        }
      ],
      "typeParameters": [
        6
      ]
    },
    {
      "typeId": 5,
      "type": "enum SetError",
      "components": [
        {
          "name": "ValueAlreadySet",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "UsernameExists",
          "type": 0,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 6,
      "type": "generic T",
      "components": null,
      "typeParameters": null
    },
    {
      "typeId": 7,
      "type": "raw untyped ptr",
      "components": null,
      "typeParameters": null
    },
    {
      "typeId": 8,
      "type": "struct Bytes",
      "components": [
        {
          "name": "buf",
          "type": 13,
          "typeArguments": null
        },
        {
          "name": "len",
          "type": 17,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 9,
      "type": "struct FinishScoreEvent",
      "components": [
        {
          "name": "username_hash",
          "type": 1,
          "typeArguments": null
        },
        {
          "name": "damage",
          "type": 17,
          "typeArguments": null
        },
        {
          "name": "top_speed",
          "type": 17,
          "typeArguments": null
        },
        {
          "name": "race_number",
          "type": 17,
          "typeArguments": null
        },
        {
          "name": "result_time",
          "type": 17,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 10,
      "type": "struct GameScore",
      "components": [
        {
          "name": "damage",
          "type": 17,
          "typeArguments": null
        },
        {
          "name": "player_id",
          "type": 17,
          "typeArguments": null
        },
        {
          "name": "finish_time",
          "type": 17,
          "typeArguments": null
        },
        {
          "name": "seq_race_number",
          "type": 17,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 11,
      "type": "struct LiveScoreEvent",
      "components": [
        {
          "name": "username_hash",
          "type": 1,
          "typeArguments": null
        },
        {
          "name": "speed",
          "type": 17,
          "typeArguments": null
        },
        {
          "name": "damage",
          "type": 17,
          "typeArguments": null
        },
        {
          "name": "distance",
          "type": 17,
          "typeArguments": null
        },
        {
          "name": "current_lap",
          "type": 17,
          "typeArguments": null
        },
        {
          "name": "seconds_racing",
          "type": 17,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 12,
      "type": "struct PlayerProfile",
      "components": [
        {
          "name": "level",
          "type": 17,
          "typeArguments": null
        },
        {
          "name": "avg_time",
          "type": 17,
          "typeArguments": null
        },
        {
          "name": "player_id",
          "type": 17,
          "typeArguments": null
        },
        {
          "name": "total_races",
          "type": 17,
          "typeArguments": null
        },
        {
          "name": "username_and_email_hash",
          "type": 1,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 13,
      "type": "struct RawBytes",
      "components": [
        {
          "name": "ptr",
          "type": 7,
          "typeArguments": null
        },
        {
          "name": "cap",
          "type": 17,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 14,
      "type": "struct RawVec",
      "components": [
        {
          "name": "ptr",
          "type": 7,
          "typeArguments": null
        },
        {
          "name": "cap",
          "type": 17,
          "typeArguments": null
        }
      ],
      "typeParameters": [
        6
      ]
    },
    {
      "typeId": 15,
      "type": "struct String",
      "components": [
        {
          "name": "bytes",
          "type": 8,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 16,
      "type": "struct Vec",
      "components": [
        {
          "name": "buf",
          "type": 14,
          "typeArguments": [
            {
              "name": "",
              "type": 6,
              "typeArguments": null
            }
          ]
        },
        {
          "name": "len",
          "type": 17,
          "typeArguments": null
        }
      ],
      "typeParameters": [
        6
      ]
    },
    {
      "typeId": 17,
      "type": "u64",
      "components": null,
      "typeParameters": null
    }
  ],
  "functions": [
    {
      "inputs": [
        {
          "name": "username",
          "type": 15,
          "typeArguments": null
        }
      ],
      "name": "all_player_scores",
      "output": {
        "name": "",
        "type": 16,
        "typeArguments": [
          {
            "name": "",
            "type": 10,
            "typeArguments": null
          }
        ]
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "all_players",
      "output": {
        "name": "",
        "type": 16,
        "typeArguments": [
          {
            "name": "",
            "type": 12,
            "typeArguments": null
          }
        ]
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "seq_id",
          "type": 17,
          "typeArguments": null
        }
      ],
      "name": "id_player_profile",
      "output": {
        "name": "",
        "type": 4,
        "typeArguments": [
          {
            "name": "",
            "type": 12,
            "typeArguments": null
          }
        ]
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "seq_id",
          "type": 17,
          "typeArguments": null
        },
        {
          "name": "race_number",
          "type": 17,
          "typeArguments": null
        }
      ],
      "name": "player_id_race_score",
      "output": {
        "name": "",
        "type": 10,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "username",
          "type": 15,
          "typeArguments": null
        }
      ],
      "name": "player_last_race_score",
      "output": {
        "name": "",
        "type": 10,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "seq_id",
          "type": 17,
          "typeArguments": null
        }
      ],
      "name": "player_username",
      "output": {
        "name": "",
        "type": 15,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "username",
          "type": 15,
          "typeArguments": null
        }
      ],
      "name": "player_username_exists",
      "output": {
        "name": "",
        "type": 2,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "players_count",
      "output": {
        "name": "",
        "type": 17,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "username",
          "type": 15,
          "typeArguments": null
        },
        {
          "name": "email",
          "type": 15,
          "typeArguments": null
        }
      ],
      "name": "register_username_email_player",
      "output": {
        "name": "",
        "type": 12,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "username",
          "type": 15,
          "typeArguments": null
        }
      ],
      "name": "register_username_player",
      "output": {
        "name": "",
        "type": 12,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "_username",
          "type": 15,
          "typeArguments": null
        },
        {
          "name": "_vehicle_damage",
          "type": 17,
          "typeArguments": null
        },
        {
          "name": "_finish_time_seconds",
          "type": 17,
          "typeArguments": null
        }
      ],
      "name": "submit_score",
      "output": {
        "name": "",
        "type": 10,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "username",
          "type": 15,
          "typeArguments": null
        },
        {
          "name": "speed",
          "type": 17,
          "typeArguments": null
        },
        {
          "name": "damage",
          "type": 17,
          "typeArguments": null
        },
        {
          "name": "distance",
          "type": 17,
          "typeArguments": null
        },
        {
          "name": "lap",
          "type": 17,
          "typeArguments": null
        },
        {
          "name": "seconds_racing",
          "type": 17,
          "typeArguments": null
        }
      ],
      "name": "submit_track_progress",
      "output": {
        "name": "",
        "type": 0,
        "typeArguments": null
      },
      "attributes": null
    },
    {
      "inputs": [
        {
          "name": "seq_id",
          "type": 17,
          "typeArguments": null
        }
      ],
      "name": "times_raced_id",
      "output": {
        "name": "",
        "type": 17,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "username",
          "type": 15,
          "typeArguments": null
        }
      ],
      "name": "times_raced_username",
      "output": {
        "name": "",
        "type": 17,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "username",
          "type": 15,
          "typeArguments": null
        }
      ],
      "name": "username_player_profile",
      "output": {
        "name": "",
        "type": 4,
        "typeArguments": [
          {
            "name": "",
            "type": 12,
            "typeArguments": null
          }
        ]
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    }
  ],
  "loggedTypes": [
    {
      "logId": 0,
      "loggedType": {
        "name": "",
        "type": 3,
        "typeArguments": []
      }
    },
    {
      "logId": 1,
      "loggedType": {
        "name": "",
        "type": 5,
        "typeArguments": []
      }
    },
    {
      "logId": 2,
      "loggedType": {
        "name": "",
        "type": 5,
        "typeArguments": []
      }
    },
    {
      "logId": 3,
      "loggedType": {
        "name": "",
        "type": 3,
        "typeArguments": []
      }
    },
    {
      "logId": 4,
      "loggedType": {
        "name": "",
        "type": 9,
        "typeArguments": []
      }
    },
    {
      "logId": 5,
      "loggedType": {
        "name": "",
        "type": 11,
        "typeArguments": []
      }
    },
    {
      "logId": 6,
      "loggedType": {
        "name": "",
        "type": 3,
        "typeArguments": []
      }
    }
  ],
  "messagesTypes": [],
  "configurables": []
};

const _storageSlots: StorageSlot[] = [
  {
    "key": "f383b0ce51358be57daa3b725fe44acdb2d880604e367199080b4379c41bb6ed",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  }
];

export class GameScoreContractAbi__factory {
  static readonly abi = _abi;

  static readonly storageSlots = _storageSlots;

  static createInterface(): GameScoreContractAbiInterface {
    return new Interface(_abi) as unknown as GameScoreContractAbiInterface
  }

  static connect(
    id: string | AbstractAddress,
    accountOrProvider: Account | Provider
  ): GameScoreContractAbi {
    return new Contract(id, _abi, accountOrProvider) as unknown as GameScoreContractAbi
  }

  static async deployContract(
    bytecode: BytesLike,
    wallet: Account,
    options: DeployContractOptions = {}
  ): Promise<GameScoreContractAbi> {
    const factory = new ContractFactory(bytecode, _abi, wallet);

    const { storageSlots } = GameScoreContractAbi__factory;

    const contract = await factory.deployContract({
      storageSlots,
      ...options,
    });

    return contract as unknown as GameScoreContractAbi;
  }
}
