import { useEffect, useState } from "react";
import {
  useConnectUI,
  useWallet
} from '@fuels/react';
// Import the contract factory -- you can find the name in src/contracts/contracts/index.ts.
// You can also do command + space and the compiler will suggest the correct name.
import { GameScoreContractAbi__factory  } from "./sway-api"
import type { GameScoreContractAbi} from "./sway-api";
import {
  BigNumberish,
  BN,
  Bytes,
  BytesLike,
  Contract,
  DecodedValue,
  FunctionFragment,
  Interface,
  InvokeFunction,
  StdString,
  TransactionResponse,
  Provider,

} from 'fuels';
import { getDecodedLogs, Wallet, WalletUnlocked } from '@fuel-ts/account';

const CONTRACT_ID = "0x0e449b6ea8f8f963677a27e6929478355b8eb73be9673793f72700219f6dcb20";
const TRX_ID = "0x097a624deca63fe7477796a6747de6d8daa867b25fadd1355122e6b74531c152";
const BLOCK_EXPLORER_URL = `https://app.fuel.network/block/${TRX_ID}/advanced`;
const CONTRACT_EXPLORER_URL = `https://app.fuel.network/contract/${CONTRACT_ID}/assets`;


const theUsername = "halo";

export default function Home() {
  interface Player {
    level: number;
    avg_time: number;
    player_id: number;
    total_races: number;
  }
  
  interface PlayerInfo {
    playerId: number;
    username: string; // Assuming username is a string
  }
  
  interface GameScore {
    damage: number;
    player_id: number;
    finish_time: number;
    seq_race_number: number;
  }
  const [contract, setContract] = useState<GameScoreContractAbi>();


  const [wallet, setWallet] = useState<WalletUnlocked | null>(null); // State to store your wallet instance
 
  useEffect(() => {
    async function getInitialCount(){
      const provider = await Provider.create("https://beta-5.fuel.network/graphql");
      const privateKey = '0xc9d916fc864bbad10be963a1ccab0bdecc5c84fdd7a767e6dc28501d8fe6156b';
 
      const myWallet: WalletUnlocked = Wallet.fromPrivateKey(privateKey, provider);
      setWallet(myWallet); // Set your wallet instance
      console.log("myWallet initialized:", myWallet);
      //const counterContract = GameScoreContractAbi__factory.connect(CONTRACT_ID, myWallet);
      if(myWallet){
         const counterContract = GameScoreContractAbi__factory.connect(CONTRACT_ID, myWallet);
        
         setContract(counterContract);
        
       }
       
    }
    
    getInitialCount();
  }, []);

  useEffect(() => {
    async function triger(){
      await triggerTrackEvent();
       
    }
    
    triger();
  }, [contract]);
  
 


  const triggerTrackEvent = async () => {
    if (!contract) {
      return console.log("Contract not loaded");
    }
    try {
      
      const callResult = await contract.functions
      .submit_track_progress(theUsername, 1, 2, 3, 4, 5)
      .call();
      const transactionResponse: TransactionResponse = callResult.transactionResponse;

      const transactionSummary = await transactionResponse.getTransactionSummary();
      //await getPlayers(contract);
      console.log("LOGS");
      console.log(callResult.transactionId);
      console.log(callResult.logs);
      console.log(callResult);
      //console.log(transactionSummary);
    } catch(error) {
      console.error(error);
    }

  };


  return (
    <div style={styles.root}>
      <div style={styles.container}>
     
          <br />
          <br />
          <a href={BLOCK_EXPLORER_URL} target="_blank" rel="noopener noreferrer">View Transaction Details</a> <br />
          <a href={CONTRACT_EXPLORER_URL} target="_blank" rel="noopener noreferrer">View Contract Details</a>
           <br />
           <button
            onClick={triggerTrackEvent}
            style={styles.button}
            >
              Trigger Track Event
            </button>
         
    </div>
    </div>
  );
}
 
const styles = {
  root: {
    display: 'grid',
    placeItems: 'center',
    height: '100%',
    width: '100%',
    backgroundColor: "black",
  } as React.CSSProperties,
  container: {
    color: "#ffffffec",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  } as React.CSSProperties,
  label: {
    fontSize: "28px",
  },
  counter: {
    color: "#a0a0a0",
    fontSize: "48px",
  },
  button: {
    borderRadius: "8px",
    marginTop: "24px",
    backgroundColor: "#707070",
    fontSize: "16px",
    color: "#ffffffec",
    border: "none",
    outline: "none",
    height: "60px",
    padding: "0 1rem",
    cursor: "pointer"
  },
}